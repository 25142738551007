<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="mt-28">
    <div class="lg:flex items-center justify-between">
      <label for="comment" class="block text-xl font-medium text-gray-900">{{loc.my_requests}}</label>
    </div>
    <p class="text-sm mt-5">{{ loc.reimbursements_desc }}</p>

    <div class="mt-5">
      <button @click="isFormOpen = true;" class="w-full lg:w-3/12 bg-base-600  hover:bg-base-500 px-4 py-1.5 md:py-2 text-white font-bold animation duration-200 ease-in-out outline-none rounded-md">
        {{ loc.new_request }}
      </button>
    </div>
    <div class="lg:flex items-center justify-between gap-4 mt-5 lg:mt-4 text-sm space-y-2 lg:space-y-0">
      <div class="lg:w-5/12 flex items-center justify-between gap-x-3">
        <div class="w-1/2">
          <button class="w-full focus:ring-2 ring-base-500 rounded-md">
            <datepicker
                class="w-full"
                v-model="startDate" name="1"
                calendar-class="shadow-xl"
                iconColor="#d1d5db"
                :clear-button="true"
                :monday-first="true"
                :placeholder="loc.date_from"
            >
              <template v-if="!startDate" v-slot:customCalendarHeader>
                <div class="h-24 w-full bg-base-600 text-white text-lg rounded-t-lg font-medium flex items-center justify-center">
                  {{ loc.date_from }}
                </div>
              </template>
              <!--            <div class="z-50">test</div>-->
            </datepicker>
          </button>
        </div>
        <div class="w-1/2">
          <button class="w-full focus:ring-2 ring-base-500 rounded-md">
            <datepicker
                class="w-full"
                v-model="endDate" name="2"
                calendar-class="shadow-xl transform -translate-x-40 md:-translate-x-0"
                iconColor="#d1d5db"
                :disabled-dates="{to: startDate}"
                :clear-button="true"
                :monday-first="true"
                :placeholder="loc.date_to"
            >
              <template v-if="!endDate" v-slot:customCalendarHeader>
                <div class="h-24 w-full bg-base-600 text-white text-lg rounded-t-lg font-medium flex items-center justify-center">
                  {{ loc.date_to }}
                </div>
              </template>
            </datepicker>
          </button>
        </div>
      </div>
      <!--  SEARCH FILTER ::START  -->
<!--      <div class="col-span-2 lg:col-span-3 xl:col-span-4">-->
<!--        &lt;!&ndash;        <label for="search" class="block text-sm text-gray-700">{{ loc.id_or_provider_name }}</label>&ndash;&gt;-->
<!--        <div class="relative flex items-center">-->
<!--          <div class="absolute inset-y-0 left-0 flex items-center justify-center pl-3.5">-->
<!--            <search-icon class="text-gray-600 h-5 w-5"/>-->
<!--          </div>-->
<!--          <input v-model.lazy="search" v-debounce="400" type="text" name="search" id="search" :placeholder="loc.id_or_provider_name"-->
<!--                 class="pl-10 w-full shadow-sm  h-10 focus:ring-base-500 focus:border-base-500 block sm:text-sm border-gray-300 rounded-md"/>-->
<!--        </div>-->
<!--      </div>-->
      <!--  SEARCH FILTER ::END  -->
      <div class="lg:w-5/12 flex items-center justify-end gap-x-3">
        <div class="w-1/2 lg:w-44 ml-auto">
          <Menu as="div" class="relative xl:w-44 p-0 m-0 z-30">
            <!--            <label for="location" class="block text-sm text-gray-700">{{ loc.status }}</label>-->
            <MenuButton class="focus:ring-2 ring-base-500 rounded-md shadow border w-full h-10 bg-white flex items-center justify-between text-gray-500 hover:text-base-600 relative px-2">
              <p class="w-5/6 truncate text-left text-base" :class="selectedStatus?.name ? 'text-black' : ''">{{ selectedStatus?.name ? parse_name(selectedStatus?.name) : loc.status }}</p>
              <font-awesome-icon v-if="selectedStatus.name" :icon="['far', 'xmark-circle']" @click.stop="setSelectedStatus('')" class="relative z-20 text-red-600 mr-1.5" />
              <font-awesome-icon  icon="fa-chevron-down" :class="$refs['statuses']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems ref="statuses" class="origin-top-right absolute mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 h-32 overflow-y-hidden">
                <div class="py-1">
                  <MenuItem v-slot="{ active }" v-for="status in statusOptions" @click="setSelectedStatus(status)">
                    <div class="flex px-2 py-2 border-b text-sm cursor-pointer hover:bg-gray-200 hover:text-base-600" :class="selectedStatus.value === status.value ? 'bg-base-600 text-white font-medium': ''">
                      {{ parse_name(status.name) }}
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="w-1/2 lg:w-24">
          <button @click="exportRefunds" class="w-full h-10 text-white bg-base-600 hover:bg-base-500 font-medium rounded-md flex items-center justify-center gap-x-1.5">
            <font-awesome-icon  icon="fa-download" />
            <p class="transform scale-110 mt-0.5">Export</p>
          </button>
        </div>
      </div>
    </div>


    <div class="mt-4 flex flex-col">
      <div class="border rounded-lg overflow-x-auto">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <div class="min-w-full divide-y-2 divide-white">
              <div class="relative h-screen-scroll">
                <div class="w-full bg-base-600 sticky top-0 z-20">
                  <div class="flex w-full text-white">
                    <div class="w-24 md:w-1/12 px-3 py-3.5 text-left text-sm font-semibold border-r-2 border-white">ID</div>
                    <div class="w-36 md:w-2/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold border-r-2 border-white">{{ loc.date }}</div>
                    <div class="w-24 md:w-1/12 px-3 py-3.5 text-left text-sm font-semibold border-r-2 border-white flex justify-center">{{loc.invoice}}</div>
                    <div class="w-40 md:w-2/12 px-3 py-3.5 text-left text-sm font-semibold border-r-2 border-white">{{loc.refund_amount}}</div>
                    <div class="w-36 md:w-2/12 px-3 py-3.5 text-left text-sm font-semibold border-r-2 border-white">{{ loc.type }}</div>
                    <div class="w-64 md:w-4/12 px-3 py-3.5 text-left text-sm font-semibold border-r-2 border-white">{{loc.comment}}</div>
                    <div class="w-20 md:w-1/12 px-3 py-3.5 text-center text-sm font-semibold ">
                      {{loc.status}}
                    </div>
                  </div>
                </div>
                <div v-if="reimbursements.results && !loading" class="divide-y-2 divide-white bg-gray-100 select-none">
                  <div v-for="reimbursement in reimbursements.results" :key="reimbursement.id" class="hover:bg-gray-50">
                    <div class="cursor-pointer flex" @click="toggle_expanded(reimbursement.id)">
                      <div class="w-24 md:w-1/12 whitespace-nowrap truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 border-r-2 border-white flex items-center">{{ reimbursement.id }}</div>
                      <div class="w-36 md:w-2/12 whitespace-nowrap truncate px-3 py-4 text-sm text-gray-600 border-r-2 border-white flex items-center">{{ formatDate(reimbursement.created_at, 'DD.MM.YYYY.') }}</div>
                      <div class="w-24 md:w-1/12 whitespace-nowrap truncate px-3 py-4 text-sm text-gray-600 border-r-2 border-white flex justify-center">
<!--                        <div v-for="invoice in reimbursement.invoice">-->
                          <a :href="reimbursement.file_url" download>
                            <DocumentIcon class="w-8 h-8 hover:text-base-500 text-center" />
                          </a>
<!--                        </div>-->
                      </div>
                      <div class="w-40 md:w-2/12 whitespace-nowrap truncate px-3 py-4 flex justify-start items-center text-sm text-center text-gray-600 font-medium border-r-2 border-white">
                        {{ monetary(reimbursement.reimbursement_amount, 'RSD') }}
                      </div>
                      <div class="w-36 md:w-2/12 whitespace-nowrap truncate px-3 py-4 flex justify-start items-center text-sm text-center text-gray-600 border-r-2 border-white">
                        {{ loc[reimbursement.refund_type] }}
                      </div>
                      <div class="flex items-center truncate w-64 md:w-4/12 text-sm text-gray-600 border-r-2 border-white px-2">
                        {{reimbursement.decline_reason ? reimbursement.decline_reason : loc.no_comment_found}}
                      </div>
                      <div class="group w-20 md:w-1/12 whitespace-nowrap flex justify-center items-center px-3 py-4 text-sm text-gray-600 px-auto relative">
                        <div class="tooltip">
                          <ClockIcon v-if="reimbursement.refund_status === null" class="w-9 h-9 text-yellow-600"/>
                          <div v-else-if="reimbursement.refund_status === false" class="border border-red-600 rounded-full flex items-center justify-center">
                            <font-awesome-icon  icon="fa-circle-xmark" class="text-red-600 h-7 w-7" />
                          </div>
                          <CheckCircleIcon v-if="reimbursement.refund_status === true" class="w-9 h-9 text-green-600"/>
                          <p v-if="reimbursement.refund_status === null" class="tooltiptext ">{{ loc.in_review }}</p>
                          <p v-if="reimbursement.refund_status === true" class="tooltiptext ">{{ loc.accepted }}</p>
                          <p v-if="reimbursement.refund_status === false" class="tooltiptext">{{ loc.rejected }}</p>
                          <!--                          <span class="hidden group-hover:flex">{{transaction.finished? loc.completed : loc.not_completed}}</span>-->
                        </div>
                      </div>
                    </div>
                    <!--  EXPANED ::START -->
<!--                    <div style="border-top: 1px solid white" :class="reimbursement.id === expanded ? 'border border-base-600 border-box border-t-0 transition duration-500 ease-in-out transform translate-y-0 opacity-100' : 'transform opacity-0 -translate-y-5'">-->
<!--                      <div v-if="reimbursement.id == expanded" class="">-->
<!--                        <div v-for="item in reimbursement.benefits_string" class="flex items-center text-sm">-->
<!--                          <div class="w-2/12 whitespace-nowrap truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r-2 border-white">-->
<!--                            <br>-->
<!--                            <br>-->
<!--                          </div>-->
<!--                          <div class="w-2/12 whitespace-nowrap truncate py-4 pl-5 pr-2 text-sm text-gray-900 sm:pl-3 border-r-2 border-white">-->
<!--                            <label class="font-medium">{{ loc.time }}</label>-->
<!--                            <p>{{ formatDate(reimbursement.created_at, 'HH:mm') }}</p>-->
<!--                          </div>-->
<!--                          <div class="w-4/12 whitespace-nowrap truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r-2 border-white">-->
<!--                            <label class="font-medium">{{ loc.name }}</label>-->
<!--                            <p class="text-base-600">{{ item.name }}</p>-->
<!--                          </div>-->
<!--                          <div class="w-2/12 whitespace-nowrap truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r-2 border-white">-->
<!--                            <label class="font-medium">{{ loc.quantity }}</label>-->
<!--                            <p class="text-base-600">{{ item.count }}</p>-->
<!--                          </div>-->
<!--                          <div class="w-2/12 whitespace-nowrap truncate py-4 pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r-2 border-white">-->
<!--                            <label class="font-medium">{{ loc.price }}</label>-->
<!--                            <p class="text-base-600">{{ item.price }}</p>-->
<!--                          </div>-->
<!--                          <div class="w-1/12 whitespace-nowrap truncate py-4 pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r-2 border-white">-->
<!--                            <br>-->
<!--                            <br>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <!--  EXPANED ::END -->
                  </div>
                </div>
                <div v-if="loading" class="h-screen-loader">
                  <ElementLoader />
                </div>
                <div v-if="reimbursements.length === 0 && !loading" class="py-40 font-medium text-sm text-gray-700 flex items-center justify-center">
                  <div class="space-y-4 text-center">
                    <font-awesome-icon  icon="fa-list-check" class="text-4xl" />
                    <p>{{ loc.no_requests_found }}</p>
                  </div>
                </div>
<!--                <div v-if="loading" class="absolute top-0 left-0 w-full h-full bg-white bg-opacity-25 font-medium flex items-center justify-center text-gray-700">{{ loc.loading }}</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      v-if="reimbursements.results"
      :disabled="loading"
      :per-page="10"
      :total-items="reimbursements.count"
      @onPrevious="fetch('previous')"
      @onNext="fetch('next')"
    />
  </div>
  <NewModal :is-open="isFormOpen" size="small" :title="loc.new_request" @onClose="isFormOpen = false">
    <ReimbursementForm @cancel="isFormOpen = false" @onSuccess="fetch; isFormOpen = false; success = loc.successfully_sent_request;" />
  </NewModal>
  <success-notification :show="success" :text="success"/>
  <error-notification :message="error" :show="!!error" />
</template>

<script>
import ReimbursementForm from "@/components/forms/ReimbursementForm.vue";
import {SearchIcon, DocumentIcon, ClockIcon, CheckCircleIcon} from "@heroicons/vue/solid";
import Pagination from "@/components/pagination.vue";
import ElementLoader from "../components/ui/elementLoader.vue";
import dayjs from 'dayjs';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {mapGetters} from "vuex";
import NewModal from "@/components/ui/NewModal.vue";
import SuccessNotification from "@/components/ui/SuccessNotification.vue";
import errorNotification from "@/components/ui/ErrorNotification.vue";

export default {
  components: {
    errorNotification,
    SuccessNotification,
    DocumentIcon,
    SearchIcon,
    ClockIcon,
    CheckCircleIcon,
    Dialog,
    ElementLoader,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Pagination,
    TransitionChild,
    NewModal,
    TransitionRoot,
    ReimbursementForm,
  },
  data() {
    return {
      reimbursements: [],
      isFormOpen: false,
      filtered: [],
      search: '',
      expanded: null,
      finishedFilter: false,
      success: null,
      nextPage: '',
      previousPage: '',
      totalItems: 0,
      selectedStatus: '',
      startDate: null,
      endDate: null,
      loading: false,
      error: null,
      statusOptions: [
        // { name: 'All / Sve', value: undefined },
        { name: 'Accepted / Prihvaćeno', value: true },
        { name: 'In review / U obradi', value: null },
        { name: 'Rejected / Odbijeno', value: false },
        // { name: 'Denied / Odbijeno', value: 'denied' },
      ]
    }
  },
  ...mapGetters('auth', {
    user: 'user'
  }),
  watch: {
    error(val) {
      if (val) {
        setTimeout(() => {
          this.error = null;
        }, 4000);
      }
    },
    // search: async function (val) {
    // await this.fetch()
    // },
    success(val) {
      if (val) {
        setTimeout(() => {
          this.success = null;
        }, 5000);
      }
    },
    finishedFilter: function (val) {
      this.fetch()
    },
    startDate() {
      this.fetch();
    },
    endDate() {
      this.fetch();
    },
    selectedStatus() {
      this.fetch();
    },
    search() {
      this.fetch();
    },
  },
  methods: {
    async exportRefunds() {
      try {
        const response = await this.axios.get('/v2/refunds/export', {
          responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'reimbursements.xlsx';

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (e) {
        this.error = this.loc.something_went_wrong;
      }
    },
    formatDate(date, format) {
      return dayjs(date).format(format);
    },
    statusHandler(status) {
      switch (status) {
        case true:
          return `&status=True`;
        case false:
          return '&status=False';
        case null:
          return '&status=null';
        default:
          return ''
      }
    },
    resetFilters() {
      this.reimbursements.results = [];
    },
    async fetch(direction = '') {
      this.loading = true;
      let url = `/v2/refunds/?search=${this.search}`;

      if (this.startDate) {
        url += `&created_from=${this.formatDate(this.startDate, 'YYYY-MM-DD')}`;
      }

      if (this.endDate) {
        url += `&created_to=${this.formatDate(this.endDate, 'YYYY-MM-DD')}`;
      }

      if (this.selectedStatus) {
        url += this.statusHandler(this.selectedStatus.value);
      }

      if (this.startDate || this.endDate || this.selectedStatus) this.resetFilters();

      if (direction === 'previous') {
        url = this.reimbursements.previous;
      }

      if (direction === 'next') {
        url = this.reimbursements.next;
      }

      let {data} = await this.axios.get(url);
      console.log(data, 'dataa');
      this.reimbursements = data;
      this.loading = false;
    },
    toggle_expanded(transaction_id) {
      if (this.expanded === transaction_id) {
        this.expanded = 0
      } else {
        this.expanded = transaction_id
      }
    },
    format(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    setSelectedStatus(status) {
      this.selectedStatus = {...status};
    },
    parse_name(name) {
      let srb = '';
      let eng = '';
      if (name.includes('/')) {
        eng = name.split('/')[0];
        srb = name.split('/')[1];
        if (this.$store.state.auth.user.language == "SR") {
          return srb;
        } else {
          return eng;
        }
      }
      return name;
    },
  },
  async mounted() {
    await  this.fetch('')
    if (this.reimbursements.length === 0) this.isFormOpen = true;
  }
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 73px !important;
  height: 22px;
  background-color: #046c9c !important;
  color: #fff !important;
  text-align: center;
  border-radius: 6px;
  padding: 1px 0;

  /* Position the tooltip */
  position: absolute;
  top: -20px;
  left: -22px;
  z-index: 1000 !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.h-screen-scroll {
  max-height: calc(100vh - 415px);
  overflow-y: auto;
}

.h-screen-loader {
  height: calc(100vh - 463px);
  overflow-y: hidden;
}

.dp__action_row {
  width: 295px !important;
  border: 1px solid red !important;
}

</style>