<template>
  <div class="p-7 relative">
    <div>
      <label class="font-medium">{{ loc.want_to_make }}</label>
      <div :class="`shadow-lg relative mx-auto lg:mx-0 flex items-center justify-between font-medium bg-gray-100 rounded-lg w-full cursor-pointer mt-2 select-none overflow-hidden`">
        <div :class="`absolute left-0 top-0 transform ${refund_type === 'transaction' ? 'translate-x-0' : 'translate-x-full'} top-0 bg-base-600 w-1/2 h-full animation duration-200 ease-in-out`"></div>
        <div @click="refund_type = 'transaction'" :class="`${refund_type === 'transaction' ? 'text-white' : ''} w-1/2  flex items-center justify-center rounded-lg h-9 animation duration-300 ease-in-out`">
          <p class="relative">{{ loc.transaction_1 }}</p>
        </div>
        <div @click="refund_type = 'reimbursement'" :class="`${refund_type !== 'transaction' ? 'text-white' : ''} w-1/2 flex items-center justify-center rounded-lg h-9 animation duration-300 ease-in-out`">
          <p class="relative">{{ loc.reimbursement_1 }}</p>
        </div>
      </div>
    </div>

    <div v-if="refund_type === 'reimbursement'" class="mt-5">
      <label class="font-medium">{{ loc.choose_benefit_category }}</label>
      <Menu as="div" class="relative w-full mt-1">
        <!--            <label for="location" class="block text-sm font-medium text-gray-700">{{ loc.category }}</label>-->
        <MenuButton :class="`rounded-md shadow border w-full h-10 bg-white flex items-center justify-between text-gray-500 relative px-2 ${isValidCategory ? 'focus:ring-2 ring-base-500 ' : 'ring-2 ring-red-600'} ${$refs['categories']?.visible ? 'ring-2 ring-base-500' : ''}`">
          <p class="w-5/6 text-left truncate text-sm" :class="selected_category?.name ? 'text-black' : ''">{{ selected_category ? parse_name(selected_category?.name) : loc.choose_benefit_category }}</p>
          <!--              <p v-if="$refs['categories']?.visible">test</p>-->
          <font-awesome-icon  icon="fa-chevron-down" :class="$refs['categories']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />
          <!--              <font-awesome-icon v-else icon="fa-chevron-up" />-->
        </MenuButton>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems ref="categories" class="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 h-56 overflow-y-scroll">
            <div class="py-1">
              <MenuItem v-slot="{ active }" v-for="category in categories" @click="setCategory(category)">
                <div class="flex px-2 py-2 border-b text-sm cursor-pointer hover:bg-gray-200 hover:text-base-600" :class="selected_category?.id === category.id ? 'bg-base-600 text-white font-medium': ''">
                  {{ parse_name(category.name) }}
                </div>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>

    <div v-if="refund_type === 'reimbursement'" class="mt-5">
      <label for="search" class="block font-medium">{{ loc.enter_amount_for_reimbursement }}</label>
      <div class="mt-1 relative flex items-center">
        <div :class="`w-full shadow-sm focus:ring-base-500 focus:border-base-500 block sm:text-sm border-gray-300 rounded-md ${!isValidAmountValue || !isValidAmount ? 'border-2 border-red-600': ''}`">
          <input @blur="validateAmount" v-model="amount" type="number" name="search" id="search" :placeholder="loc.enter_amount_for_reimbursement"
                 class="w-full h-10 shadow-sm focus:ring-base-500 focus:border-base-500 block sm:text-sm border-gray-300 rounded-md px-3"/>
        </div>
      </div>
      <small v-if="!isValidAmountValue" class="text-red-600">*{{ loc.reimbursement_amount_error }}</small>
    </div>

    <div class="mt-7">
      <input @change="handleFileChange($event)" hidden type="file" ref="import_users" />
      <p v-if="file && file.name" class="my-1">{{ file.name }}</p>
      <button @click="$refs.import_users.click()" type="button" :class="`flex justify-center items-center py-6 w-full border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm text-base-600 hover:bg-gray-50 focus:outline-none ${isValidFile ? 'focus:ring-2 focus:ring-offset-2 focus:ring-base-500' : 'ring-2 ring-offset-2 ring-red-600'}`">
        <div class="space-y-3">
          <p>{{ loc.upload_document }}</p>
          <font-awesome-icon icon="fa-solid fa-upload" class="h-6 w-6" />
        </div>
      </button>
    </div>
    <div class="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 pt-6 mt-3 border-t w-full bg-white">
      <button @click="$emit('cancel')" class="w-1/2 h-10 bg-white text-base-600 hover:bg-base-600 hover:text-white font-medium border-2 border-base-600 rounded-lg animation duration-200 ease-in-out">{{ loc.cancel }}</button>
      <button @click="sendNewRequest" :disabled="!isFormValid" :class="`${isFormValid ? 'bg-base-600 hover:bg-base-700' : 'bg-base-600 bg-opacity-75 cursor-not-allowed'}  w-1/2 h-10 text-white font-medium rounded-lg animation duration-200 ease-in-out`">{{ loc.send }}</button>
    </div>
  </div>
  <error-notification :show="error != null" :message="error" />
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  ArrowsExpandIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MenuIcon,
  SearchIcon,
  ViewGridIcon
} from "@heroicons/vue/solid";
import errorNotification from "@/components/ui/ErrorNotification.vue";
import SuccessNotification from "@/components/ui/SuccessNotification.vue";

export default {
  name: "ReimbursementForm",
  data() {
    return {
      refund_type: 'transaction',
      selected_category: null,
      file: null,
      amount: null,
      error: null,
      success: null,
      isValidDocument: true,
      isValidAmount: true,
      isValidCategory: true,
      isValidFile: true,
      base64String: '',
    }
  },
  components: {
    SuccessNotification,
    errorNotification,
    SearchIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    ViewGridIcon,
    MenuIcon,
    ArrowsExpandIcon,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
  },
  computed: {
    ...mapState('filters', ['categories']),
    ...mapGetters('auth', {
      user: 'user'
    }),
    isValidAmountValue() {
      return Number(this.user.balance) >= this.amount && this.amount > -1;
    },
    isFormValid() {
      if (this.refund_type === 'transaction') {
        if (!this.base64String) return false;
        else return true;
      } else {
        if (!this.amount || !this.isValidAmountValue || !this.base64String || !this.selected_category) return false;
        else return true;
      }
    }
  },
  methods: {
    validateAmount() {
      if ((!this.isValidAmountValue || this.amount < 1 || !this.amount) && (Number(this.user.balance) < this.amount)) {
        this.isValidAmount = false;
      } else {
        this.isValidAmount = true;
      }
    },
    validateCategory() {
      if (!this.selected_category) {
        this.isValidCategory = false;
      } else {
        this.isValidCategory = true;
      }
    },
    validateFile() {
      if (!this.file) {
        this.isValidFile = false;
      } else {
        this.isValidFile = true;
      }
    },
    handleFileChange(event) {
      let file = event.target.files[0];
      this.file = file;
      const maxSize = 2 * 1024 * 1024; // 2MB u bajtovima
      const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];

      if (file.size > maxSize) {
        file = null;
        this.error = 'Datoteka je prevelika. Maksimalna veličina je 2MB.'
        return;
      }

      if (!allowedTypes.includes(file.type)) {
        file = null;
        this.error = 'Nepodržan format datoteke. Dozvoljeni formati su PDF, JPEG, PNG.'
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.base64String = reader.result;
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };
      }
      this.validateFile();
    },
    async sendNewRequest() {
      if (this.refund_type === 'reimbursement') {
        await this.validateCategory();
        await this.validateAmount();
      }
      await this.validateFile();
      if (this.isFormValid) {
        const payload = {
          refund_type: this.refund_type,
          file_url: this.base64String,
        };
        if (this.refund_type === 'reimbursement') {
          payload.reimbursement_amount = this.amount;
          payload.category = this.selected_category.id;
        }

        try {
          await this.axios.post('/v2/refunds/', payload);
          this.$emit('onSuccess')
        } catch (e) {
          console.log(e, "err");
          this.error = 'Nešto nije u redu!';
        }
      } else {
        this.error = 'Sva polja moraju biti validna!';
      }
    },
    async import_file(e) {
      this.file = e.target.files[0];
      const maxSize = 2 * 1024 * 1024; // 2MB u bajtovima
      const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];

      if (this.file.size > maxSize) {
        this.file = null;
        this.error = 'Datoteka je prevelika. Maksimalna veličina je 2MB.'
        return;
      }

      if (!allowedTypes.includes(this.file.type)) {
        this.file = null;
        this.error = 'Nepodržan format datoteke. Dozvoljeni formati su PDF, JPEG, PNG.'
        return;
      }

      // let req = new FormData();
      // req.append('company', this.$route.params.id);
      // req.append('file', this.file);
      //
      // console.log(this.file, 'file');

      // try {
      //   const response = await axios.post('/api/upload', req);
      //   console.log('Uspešan upload', response.data);
      // } catch (error) {
      //   console.error('Greška pri uploadu', error);
      // }
    },
    setCategory(category) {
      this.selected_category = category;
      this.isValidCategory = true;
    },
    parse_name(name) {
      let srb = '';
      let eng = '';
      if (name.includes('/')) {
        eng = name.split('/')[0];
        srb = name.split('/')[1];
        if (this.$store.state.auth.user.language === "SR") {
          return srb;
        } else {
          return eng;
        }
      }
      return name;
    },
  },
  watch: {
    error(val) {
      if (val) {
        setTimeout(() => {
          this.error = null;
        }, 5000);
      }
    },
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>